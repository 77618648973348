.form-control {
  box-shadow: none;
  outline: transparent;
  border: 1px solid #f4f4f4;
  border-radius: 0px;
  background-color: #f4f4f4;
  font-size: 0.9rem;
  font-weight: 400;
  color: #131313;
  &.text-green {
    color: var(--jk-clr-03) !important;
    &::placeholder {
      color: var(--jk-clr-03) !important;
    }
  }
  &:focus {
    background-color: #f4f4f4;
    box-shadow: none;
    outline: transparent;
    border: 1px solid #f4f4f4;
  }
  &::placeholder {
    font-size: 0.9rem;
    color: #131313;
    font-weight: 400;
  }
}
