.review-card {
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  max-width: 50rem;
  border-bottom: 1px solid #00000026;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 1px solid transparent;
  }
  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    .rating {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.1875rem 0.375rem;
      gap: 0.25rem;
      border-radius: 0.25rem;
      border: 1px solid var(--jk-clr-03);
      background: var(--jk-clr-03);
      @include media-min-sm {
        padding: 0.375rem 0.5rem;
      }
      span {
        color: var(--jk-clr-01);
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.75rem; /* 100% */
        @include media-min-sm {
          font-size: 1rem;
          line-height: 1rem; /* 100% */
        }
      }
      img {
        filter: invert(1);
        width: 0.75rem !important;
        height: 0.75rem !important;
        @include media-min-sm {
          width: 1rem !important;
          height: 1rem !important;
        }
      }
    }
    .review-title {
      font-size: 1.25rem;
      line-height: normal;
      margin-bottom: 0;
      color: var(--jk-clr-02);
      font-weight: 400;
    }
  }
  .description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 0;
    color: var(--jk-clr-02);
    font-weight: 400;
  }
  .author-details {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    width: fit-content;
    span {
      font-size: 0.85rem;
      line-height: normal;
      color: var(--jk-clr-02);
      font-weight: 600;
      &.date {
        font-weight: 400;
        color: var(--jk-clr-04);
      }
    }
  }
}
