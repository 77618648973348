.payItem-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  @include media-min-lg {
    max-width: 25rem;
  }
  .card-image {
    border: 1px solid var(--jk-clr-08);
    background-color: transparent;
    width: 5rem;
    height: 5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      mix-blend-mode: multiply;
    }
  }
  .content {
    width: calc(100% - 6rem);
    p {
      font-size: 1rem;
      line-height: 1.6;
      margin-bottom: 0;
      color: var(--jk-clr-02);
    }
    .size-price-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 1rem;
        color: var(--jk-clr-02);
      }
    }
  }
}
