.auth-section {
  min-height: 100vh;
  min-height: 100vh;
  position: relative;
  background: #fff;
  background: linear-gradient(132deg, var(--jk-clr-03), var(--jk-clr-07), var(--jk-clr-02));
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
  .content-section {
    min-height: 100vh;
    z-index: 999;
    position: relative;
    display: flex;
    align-items: center;
    padding: 2rem;
    overflow: hidden;
    background: #fff;
    border-radius: 0 0 350px 0;
    &::before {
      @include media-min-xl {
        content: "";
        width: 20%;
        height: 20%;
        position: absolute;
        top: 2.5rem;
        left: 2.5rem;
        border-radius: 100% 0 100% 100%;
        background-image: linear-gradient(to bottom, #ffb100, #ff8e00);
      }
    }
    &::after {
      @include media-min-xl {
        content: "";
        width: 30%;
        height: 60%;
        position: absolute;
        top: 20%;
        right: 0;
        z-index: -1;
        -webkit-clip-path: polygon(0 0, 30% 0, 70% 10%);
        clip-path: polygon(0 0, 100% 50%, 100% 80%);
        background-image: linear-gradient(to bottom, #ffb100, #ff8e00);
      }
    }
    h1{
      color: rgba(255,0,0,0.1);
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      background-position: top center;
      background-image: url('../../images/heading-bg.jpeg.crdownload');
      -webkit-background-clip: text;
      background-clip: text; // use text as clipping mask for bg
      animation: background-text-animation 15s linear infinite;
      margin-bottom: 1.5rem;
      @include media-min-xl {
          font-size: 3rem;
      }
    }
    p {
      font-size: 1rem;
      line-height: normal;
      margin-bottom: 0;
      color: var(--jk-clr-02);
    }
  }
  .form-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 1rem;
    .login-title {
      width: 100%;
      text-align: center;
      h2 {
        text-transform: uppercase;
        font-size: 2rem;
        line-height: normal;
        color: var(--jk-clr-01);
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 1.25rem;
        line-height: normal;
        color: var(--jk-clr-01);
        margin-bottom: 0;
      }
    }
    form {
      max-width: 28rem;
      width: 100%;
      margin: 2rem auto 0 auto;
      p,a {
        margin-bottom: 0;
        color: var(--jk-clr-01);
        font-size: 1rem;
      }
      .form-control {
        font-size: 1rem !important;
        outline: none;
        color: #535353 !important;
        border-radius: 0.25rem;
        border: 1px solid #efefef !important;
        background: #efefef !important;
        width: 100%;
        padding: 0.6rem 1rem 0.6rem 1rem;
        &:focus {
            box-shadow: none;
        }
      }
      .forget-password__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .remember-me__wrapper {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        a {
          text-decoration: none;
          color: var(--jk-clr-01);
          font-size: 1rem;
        }
      }
      .btn-org {
        color: #fff;
        text-align: center;
        border: 2px solid transparent;
        display: inline-block;
        padding: 0.5rem 2rem;
        position: relative;
        line-height: 1.6;
        z-index: 1;
        transition: all .5s ease;
        border-radius: 0.25rem;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 400;
        font-family: 'Jost', sans-serif;
        width: 100%;
        background: linear-gradient(to bottom, #ffb400, #ff8a00);

        &:hover{
          color: #ffb400 !important;
          border: 2px solid #ffb400 !important;
          background: transparent !important;
        }
      }
    }
  }
}


@keyframes Gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

@keyframes background-text-animation {
  0% {
      background-position: left 0px top 50%;
  }
  50% {
      background-position: left 1500px top 50%;
  }
  100% {
      background-position: left 0px top 50%;
  }
}
