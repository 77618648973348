.banner__section {
  .banner-wrapper {
    position: relative;
    padding: 3rem 1rem;
    @include media-min-md {
      padding: 6rem 3rem;
    }
    @include media-min-lg {
      padding: 8rem 6rem;
    }
    .background__overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      border-radius: 1.5rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .banner__content {
      max-width: 35rem;
      h1 {
        margin-bottom: 1.5rem;
        color: var(--jk-clr-02);
        font-weight: 600;
        line-height: normal;
        @include media-min-lg {
          font-size: 3rem;
        }
      }
      p {
        font-size: 1rem;
        line-height: normal;
        color: var(--jk-clr-02);
        margin-bottom: 1rem;
        display: none;
        @include media-min-md {
          display: block;
        }
      }
    }
  }
}
