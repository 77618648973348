
.land-header {
  font-family: $font-family-2;
  position: sticky;
  top: 0;
  background-color: var(--jk-clr-01);
  z-index: 99;
  nav {
    padding: 0.5rem 0;
    box-shadow: 0px 1px 0px 0px rgba(40, 84, 48, 0.21);
    .navbar-brand {
      font-size: 1.5rem;
      line-height: 1.65;
      color: var(--jk-clr-03);
      font-weight: 700;
    }
    .menu-items {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--jk-clr-01);
      transform: translateX(100%);
      @include media-min-md {
        transform: unset;
        background: none;
        display: flex;
        align-items: center;
        position: unset;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        height: 100%;
        padding: 1rem 0.75rem;
        @include media-min-md {
          padding: 0;
          background-color: transparent;
          width: auto;
          height: unset;
          flex-direction: row;
          align-items: center;
          gap: 1.5rem;
        }
        @include media-min-lg {
          gap: 2rem;
        }
        .nav-item {
          .nav-link {
            font-size: 1rem;
            line-height: 26px;
            color: var(--jk-clr-02);
            font-weight: 400;
            position: relative;
            transition: all 0.3s ease-in-out;
            &::after {
              @include media-min-xl {
                position: absolute;
                content: '';
                display: block;
                width: 0;
                height: 3px;
                background-color: var(--jk-clr-03);
                transition: all 0.3s ease-in-out;
              }
            }
            &:hover,&.active {
              color: var(--jk-clr-03);
              &::after {
                width: 75%;
              }
            }
          }
        }
      }
    }
    .menu-icons {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      cursor: pointer;
      .icon {
        width: 1.5rem;
        height: 1.5rem;
      }
      .auth-btn {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: var(--jk-clr-03);
        color: var(--jk-clr-01);
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
        border: 1px solid transparent;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: transparent;
          border: 1px solid var(--jk-clr-03);
          color: var(--jk-clr-03);
        }
      }
    }
    .menu-dropdown-items {
      position: initial;
      @include media-min-sm {
        position: relative;
      }
      button {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        span {
          display: none;
          font-size: 1rem;
          line-height: 1.5;
          color: var(--jk-clr-02);
          @include media-min-lg {
            display: block;
          }
        }
      }
      ul {
        margin-top: 0.5rem;
        width: calc(100% - 2rem);
        left: 1rem;
        right: 1rem;
        border-radius: 0.75rem;

        &.notification-popup {
          padding: 0;
          @include media-min-sm {
            min-width: 20rem !important;
            max-width: 20rem !important;
          }
          @include media-min-md {
            min-width: 26rem !important;
            max-width: 26rem !important;
          }
        }

        @include media-min-sm {
          right: 0;
          left: auto;
          width: 100%;
          min-width: 15rem;
          max-width: 15rem;
        }
        li {
          .dropdown-menu-item {
            cursor: pointer;
            padding: 0.6rem 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            span {
              font-size: 13px;
              line-height: normal;
              font-weight: 400;
            }
          }
          .line {
            height: 1px;
            width: 100%;
            background-color: var(--jk-clr-04);
          }
          .nofitication-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            padding: 0.75rem 1rem;
            border-bottom: 1px solid var(--jk-clr-06);
            h2 {
              margin: 0;
              color: var(--jk-clr-02);
              font-size: 1rem;
              line-height: 1.65;
            }
            span {
              font-size: 0.75rem;
              color: var(--jk-clr-04);
            }
          }
          .nofitication-body {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 0.75rem 1rem;
            max-height: 20rem;
            overflow-y: auto;
            @include scroll-bar(0.15rem,0.15rem);
            @include media-min-md {
              max-height: 30rem;
            }

            .notification-item {
              display: flex;
              gap: 1rem;
              padding-bottom: 0.75rem;
              border-bottom: 1px solid var(--jk-clr-06);
              cursor: pointer;
              &:last-child {
                padding-bottom: 0;
                border-bottom: none;
              }
              .profile-icon {
                width: 2.5rem;
              }
              .notification-detail-wrapper {
                width: calc(100% - 3.5rem);
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                .notificaton-details {
                  width: 100%;
                  .title-wrapper {
                    display: flex;
                    gap: 0.5rem;
                    margin-bottom: 0.25rem;
                    h6 {
                      margin: 0;
                      color: var(--jk-clr-02);
                      font-size: 1rem;
                      line-height: normal;
                      white-space: nowrap;
                      width: calc(100% - 0.5rem);
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                    .close-icon {
                      svg {
                        font-size: 1rem;
                      }
                    }
                  }
                  p {
                    margin: 0;
                    color: var(--jk-clr-04);
                    font-size: 0.8rem;
                    line-height: normal;
                    white-space: nowrap;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
                .notification-timing-details {
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  width: 100%;

                  .time-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 0.25rem;
                    svg {
                      font-size: 0.75rem;
                    }
                    span {
                      margin: 0;
                      color: var(--jk-clr-02);
                      font-size: 0.75rem;
                    }
                  }
                }
              }
            }
            .nodata-found {
              p {
                margin: 0;
                color: var(--jk-clr-02);
                font-size: 0.8rem;
                line-height: normal;
                text-align: center;
              }
            }
          }
          .notification-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.75rem 1rem;
            border-top: 1px solid var(--jk-clr-06);
            p {
              margin: 0;
              color: var(--jk-clr-03);
              font-size: 0.8rem;
              line-height: normal;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .profile-image__icon {
    cursor: pointer;
    margin: 5px;
    padding: 8px !important;
    text-align: center;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}


.details-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0.75rem;
  position: sticky;
  top: 0;
  background-color: var(--jk-clr-01);
  z-index: 99;
  box-shadow: 0px 1px 2px 0px var(--jk-clr-03);
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
  .navbar-brand {
    font-size: 1.5rem;
    line-height: 1.65;
    color: var(--jk-clr-03);
    font-weight: 700;
  }
}
