// ==== Standard Bootstrap Breakpoints ====

$screen-sm: 576px;
$screen-md: 767px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

// ==== Custom Breakpoints ====

$screen-alt-363: 363px;
$screen-alt-480: 480px;
$screen-alt-768: 768px;
$screen-alt-1024: 1024px;
$screen-alt-1350: 1350px;
$screen-alt-1500: 1500px;
$screen-alt-1600: 1600px;

// 2560 x 1600
// 1920 × 1080 (8.89%)
// 1536 × 864 (3.88%)
// 1366 × 768 (8.44%)
// 1024 x 768

// 480 width
// 375 width

// no use
// 414×896 (4.58%)
// 375×667 (3.75%)
// 360×640 (7.28%)

/*------ font family variable --------
==================================================*/

$font-family: 'Poppins', sans-serif;
$font-family-1: 'Urbanist', sans-serif;
$font-family-2: 'Jost', sans-serif;
$font-family-3 : 'Josefin Sans', sans-serif;

:root {
  --jk-clr-01 : #ffffff;
  --jk-clr-02 : #000000;
  --jk-clr-03 : #285430;
  --jk-clr-04 : #4F4F4F;
  --jk-clr-05 : #E5D9B6;
  --jk-clr-06 : #eaedf1;
  --jk-clr-07 : #388e3c;
  --jk-clr-08 : #d6d6d6;
  --jk-clr-09 : #333131;
}

