.aboutUs__section {
  .aboutUs_wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include media-min-lg {
      flex-direction: row;
      gap: 2rem;
    }
    .image_wrapper {
      width: 100%;
      @include media-min-lg {
        width: calc(50% - 1rem);
      }
    }
    .comtent_wrapper {
      width: 100%;
      @include media-min-lg {
        width: calc(50% - 1rem);
      }
    }
  }
}
