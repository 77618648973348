.deals-of-the-day__Section {
  .deals-of-the-day__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(100%), 1fr)) !important;
    gap: 1rem;
    @include media-min-sm {
      grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), 1fr)) !important;
    }
    @include media-min-lg {
      grid-template-columns: repeat(auto-fill, minmax(calc(33.3% - 1rem), 1fr)) !important;
    }
    @include media-min-xl {
      gap: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(calc(25% - 2rem), 1fr)) !important;
    }
    .deals_card {
      display: flex;
      border-radius: 0.75rem;
      overflow: hidden;
      flex-direction: column;

      .card-image {
        height: 15rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .card-content {
        background-color: var(--jk-clr-03);
        padding: 0.75rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        h1 {
          color: var(--jk-clr-01);
          font-size: 1rem;
          font-weight: 500;
          line-height: normal; /* 100% */
          margin-bottom: 0;
          @include lineBreak(2);
        }
        .rating {
          display: flex;
          padding: 0.375rem 0.5rem;
          justify-content: center;
          align-items: center;
          gap: 0.25rem;
          border-radius: 0.25rem;
          border: 1px solid var(--jk-clr-05);
          background: var(--jk-clr-05);
          width: fit-content;
          span {
            color: #000;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem; /* 100% */
          }
          img {
            width: 1rem;
            height: 1rem;
          }
        }
        .price {
          margin: 0;
          color: var(--jk-clr-01);
          font-size: 1rem;
          font-weight: 600;
          line-height: 1rem; /* 100% */
        }
        .add-to-cart {
          display: flex;
          padding: 0.25rem 0.5rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-radius: 0.25rem;
          background: var(--jk-clr-05);
          width: fit-content;
          margin-left: auto;
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
          span {
            color: var(--jk-clr-02);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1rem; /* 100% */
          }
        }
      }
    }
  }
}
