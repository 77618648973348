.testimonial__card {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  background-color: var(--jk-clr-03);
  border-radius: 0.5rem;
  height: 100%;
  .testimonial_body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .icon {
      img {
        width: 1rem;
        height: 1rem;
      }
    }
    h6 {
      margin: 0;
      color: var(--jk-clr-01);
      font-size: 1.5rem;
      font-weight: 600;
      line-height: normal; /* 80% */
    }
    p {
      margin: 0;
      color: var(--jk-clr-01);
      font-size: 1rem;
      font-weight: 400;
      line-height: normal;
    }
    .author {
      margin: 0;
      color: var(--jk-clr-01);
      text-align: right;
      font-size: 1rem;
      font-weight: 700;
      line-height: normal; /* 100% */
    }
  }
}
