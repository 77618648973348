$buttons: "white" #ffffff #000,"green" #285430 #fff,"blue" #1773b0 #fff;

@each $value in $buttons {
  .btn-#{ nth($value, 1) } {
    background-color: nth($value, 2) !important;
    color: nth($value, 3) !important;
    border: 1px solid transparent;
    &:active {
      background-color: nth($value, 2) !important;
      border-color: nth($value, 2) !important;
      color: nth($value, 3) !important;
    }
    &:hover {
      background-color: nth($value, 2) !important;
      border-color: nth($value, 2) !important;
      color: nth($value, 3) !important;
      opacity: 0.9;
    }
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

@each $value in $buttons {
  .btn-outline-#{ nth($value, 1) } {
    background-color: #fff !important;
    color: nth($value, 2) !important;
    border: 1px solid nth($value, 2 );
    &:active {
      background-color: nth($value, 2) !important;
      border-color: nth($value, 2) !important;
      color: nth($value, 3) !important;
    }
    &:hover,&.active {
      background-color: nth($value, 2) !important;
      border-color: nth($value, 2) !important;
      color: nth($value, 3) !important;
    }
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
