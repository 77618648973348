.bestSells_card {
  display: flex;
  border-radius: 0.75rem;
  overflow: hidden;

  .card-image {
    width: 5rem;
    @include media-min-sm {
      width: 8rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-content {
    background-color: var(--jk-clr-03);
    padding: 0.75rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: calc(100% - 5rem);
    @include media-min-sm {
      width: calc(100% - 8rem);
    }
    h1 {
      color: var(--jk-clr-01);
      font-size: 1rem;
      font-weight: 500;
      line-height: normal; /* 100% */
      margin-bottom: 0;
      @include lineBreak(1);
    }
    .solid_and_rating {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .rating {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.1875rem 0.375rem;
        gap: 0.25rem;
        border-radius: 0.25rem;
        border: 1px solid var(--jk-clr-05);
        background: var(--jk-clr-05);
        @include media-min-sm {
          padding: 0.375rem 0.5rem;
        }
        span {
          color: var(--jk-clr-02);
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 0.75rem; /* 100% */
          @include media-min-sm {
            font-size: 1rem;
            line-height: 1rem; /* 100% */
          }
        }
        img {
          width: 0.75rem !important;
          height: 0.75rem !important;
          @include media-min-sm {
            width: 1rem !important;
            height: 1rem !important;
          }
        }
      }
      .line {
        width: 0.0625rem;
        height: 1rem;
        background: #D9D9D9;
        display: none;
        @include media-min-alt(360px) {
          display: block;
        }
      }
      p {
        margin: 0;
        color: #FFF;
        font-style: normal;
        font-weight: 600;
        font-size: 0.65rem;
        line-height: 0.65rem; /* 100% */
        display: none;
        @include media-min-alt(360px) {
          display: block;
        }
        @include media-min-sm {
          font-size: 1rem;
          line-height: 1rem; /* 100% */
        }
      }
    }
    .price {
      margin: 0;
      color: var(--jk-clr-01);
      font-size: 1rem;
      font-weight: 600;
      line-height: 1rem; /* 100% */
    }
    .add-to-cart {
      display: flex;
      padding: 0.25rem 0.5rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.25rem;
      background: var(--jk-clr-05);
      width: fit-content;
      margin-left: auto;
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
      span {
        color: var(--jk-clr-02);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1rem; /* 100% */
      }
    }
  }
}
