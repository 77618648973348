.badge {
  padding: 0.45rem 0.9rem;
  font-size: 0.7rem;
  font-weight: 400;
  border-radius: 5px;
  white-space: nowrap;

  &.completed {
    background-color: #8fbd5640;
    color: #8fbd56;
    border: 1px solid #8fbd56;
  }
  &.overdue {
    background-color: #e984b14f;
    color: #e984b1;
    border: 1px solid #e984b1;
  }
  &.inProgress {
    background-color: #59adec40;
    color: #59adec;
    border: 1px solid #59adec;
  }
  &.hours {
    background-color: #2a254d;
    color: #fff;
    border: 1px solid #2a254d;
  }
}
