.card3 {
  display: flex;
  padding: 0.75rem 1rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 2px solid var(--jk-clr-03);

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    h1 {
      margin: 0;
      color: var(--jk-clr-03);
      font-size: 1.5rem;
      font-weight: 500;
      line-height: normal; /* 100% */
    }
    p {
      margin: 0;
      color: #4F4F4F;
      font-size: 1rem;
      font-weight: 400;
      line-height: normal; /* 100% */
    }
  }
}
