.footer {
  padding: 3rem 0;
  .our_services {
    display: none;
    @include media-min-md {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), 1fr)) !important;
      padding: 0rem 0 2rem 0;
    }
    @include media-min-xl {
      gap: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(calc(25% - 2rem), 1fr)) !important;
    }
  }
  .footer-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(100%), 1fr)) !important;
    gap: 1rem;
    @include media-min-sm {
      grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), 1fr)) !important;
    }
    @include media-min-lg {
      grid-template-columns: repeat(auto-fill, minmax(calc(25% - 1rem), 1fr)) !important;
    }
    @include media-min-xl {
      gap: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(calc(20% - 2rem), 1fr)) !important;
    }
    .menus__list {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .footer-menu__title {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        h2 {
          margin: 0;
          color: var(--jk-clr-03);
          font-size: 1.5rem;
          font-weight: 700;
          line-height: normal; /* 100% */
        }
        h4 {
          margin: 0;
          color: var(--jk-clr-03);
          font-size: 1.5rem;
          font-weight: 500;
          line-height: normal; /* 100% */
        }
        p {
          margin: 0;
          color: var(--jk-clr-04);
          font-size: 1rem;
          font-weight: 400;
          line-height: normal; /* 100% */
        }
      }
      .menu-item-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        ul {
          margin: 0;
          padding: 0;
          list-style-position: inside;
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          li {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;
            img {
              width: 1rem;
              height: 1rem;
            }
            color: var(--jk-clr-02);
            font-size: 1rem;
            font-weight: 400;
            line-height: normal; /* 100% */
            a {
              text-decoration: none;
              color: var(--jk-clr-02);
              font-size: 1rem;
              font-weight: 400;
              line-height: normal; /* 100% */
            }
          }
        }
        .app-links {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          img {
            width: calc(50% - 0.75rem);
            height: auto !important;
          }
        }
        p {
          margin: 0;
          color: var(--jk-clr-04);
          font-size: 1rem;
          font-weight: 400;
          line-height: normal; /* 100% */
        }
      }
    }
  }
}


.mobile-footer {
  display: flex;
  width: 100%;
  padding: 0.75rem 0;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 2px solid var(--jk-clr-03);
  background: var(--jk-clr-01);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    .menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      text-decoration: none;
      cursor: pointer;
      &.active {
        svg {
          path {
            stroke: var(--jk-clr-03);
            stroke-opacity: 1;
          }
        }
        p {
          color: var(--jk-clr-03);
        }
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        path {
          stroke: var(--jk-clr-02);
          stroke-opacity: 0.5;
        }
      }
      p {
        margin: 0;
        color: rgba(0,0,0,0.5);
        font-size: 1rem;
        font-weight: 500;
        line-height: normal; /* 100% */
      }
    }
  }

}
