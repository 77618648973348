* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body {
  background-color: #fff;
  font-family: $font-family-2 !important;
  padding-bottom: 5.313rem;
  @include media-min-md {
    padding-bottom: 0;
  }
}

.product-page-pb-5 {
  padding-bottom: 7.5rem;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.section_padding {
  padding: 2rem 0;
}

.section__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    color: var(--jk-clr-03);
    font-family: $font-family-2 !important;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    @include media-min-sm {
      font-size: 2rem;
    }
  }
  .cus-arrow {
    display: flex;
    align-items: center;
    gap: 1rem;
    svg {
      cursor: pointer;
    }
  }
}

input[type="checkbox"]:checked ~ label {
  .cus-checkbox  {
    background: var(--jk-clr-01);
    &.checkbox-dark {
      background: var(--jk-clr-02) !important;
    }
    img {
      opacity: 1;
    }
  }
}

.form-check-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .cus-checkbox {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    border: 2px solid var(--jk-clr-01);
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    transition: all 0.3s ease;
    cursor: pointer;
    &.checkbox-dark {
      border: 2px solid var(--jk-clr-02) !important;
      img {
        filter: invert(1);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      opacity: 0;
    }
  }
  span {
    font-size: 1rem;
    color: var(--jk-clr-01);
    line-height: normal;
    margin-bottom: 0;
  }
}


.nocart-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  &.page-nocart__wrapper {
    height: calc(100vh - 10rem);
    @include media-min-md {
      height: 60vh;
    }
  }
  img {
    max-width: 15rem;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  p {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.6;
    color: var(--jk-clr-02);
  }
}
