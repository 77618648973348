.product-share__popup {
  background-color: var(--jk-clr-01);
  max-width: 500px;
  min-width: 280px;
  margin: 0 auto;
  font-family: $font-family-3;
  border: none;
  overflow: hidden;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .card-header {
    display: flex;
    align-items: center;
    position: sticky !important;
    background-color: var(--jk-clr-01);
    padding: 1rem;
    top: 0;
  }
  .title {
    font-size: 1rem;
    line-height: normal;
    color: var(--jk-clr-02);
    margin-bottom: 0.5rem;
    position: relative;
    &::after {
      margin-top: 0.3rem;
      display: block;
      content: '';
      width: 50px;
      height: 4px;
      background-color: var(--jk-clr-02);
    }
  }
  .close_icon {
    margin-left: auto;
    display: block;
    width: fit-content;
    cursor: pointer;
    display: none;
    @include media-min-md {
      display: block;
    }
    svg {
      font-size: 1.5rem;
    }
  }
  .product-share_inlink {
    padding: 0 1rem 1rem 1rem;
    .product-copylink__wrapper {
      width: 100%;
      display: flex;
      input {
        border-radius: 0px;
        outline: none;
        border: 1px solid #dedede;
        height: 2.5rem;
        padding: 0 0.625rem;
        font-size: 1rem;
        max-width: calc(100% - 100px);
        flex: 1;
        &:focus{
          border: 1px solid #dedede;
        }
        @include media-min-lg {
          min-width: 250px;
        }
      }
      .btn-copy {
        flex: 1;
        border-radius: 0px;
        background-color: var(--jk-clr-02);
        color: var(--jk-clr-01);
        font-size: 1rem;
        border: 1px solid var(--jk-clr-02);
        height: 2.5rem;
        padding: 0 1rem;
        max-width: 100px;
        &:hover {
          border: 1px solid var(--jk-clr-03);
          background-color: var(--jk-clr-03);
          color: var(--jk-clr-01);
        }
        &:active {
          border: 1px solid var(--jk-clr-03);
        }
      }
    }
  }
  .product-share_socialmedia {
    width: 100%;
    padding: 0 1rem 1rem 1rem;
    .social-media__wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
      .icons {
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid #dedede;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 1.5rem;
          fill: var(--jk-clr-03);
        }
        &:hover {
          background-color: var(--jk-clr-03);
          border: 1px solid var(--jk-clr-03);
          svg {
            fill: var(--jk-clr-01);
          }
        }
      }
    }
  }
}
