.landing-page__category___section {
  .category-container {
    display: flex;
    gap: 1rem;
    .card {
      border: none;
      display: flex;
      padding: 1rem 1.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      border-radius: 8px;
      background: rgba(40, 84, 48, 0.12);
      cursor: pointer;
      gap: 1.5rem;
      img {
        width: 3rem;
        height: 3rem;
        flex-shrink: 0;
      }
      .content {
        h2 {
          color: #000;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.5rem; /* 100% */
        }
        p {
          color: #000;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem; /* 100% */
          margin-bottom: 0;
        }
      }
    }
  }
}
