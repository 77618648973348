.shippingform__section {
  .shippingform_wrappper {
    @include media-min-lg {
      display: flex;
    }
    .form__Section {
      padding: 1rem;
      @include media-min-lg {
        width: 60%;
        padding: 3rem 1rem;
      }
      .heading {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: normal;
        color: var(--jk-clr-02);
        margin-bottom: 1rem;
      }
      form {
        @include media-min-lg {
          max-width: 40rem;
          margin-left: auto;
        }
        .form-control {
          background-color: transparent !important;
          border: 1px solid #dedede;
          padding: 0.8rem 1rem;
          border-radius: 0.5rem;
        }
        .apartment_field_btn {
          width: fit-content;
          font-size: 1rem;
          display: flex;
          align-items: center;
          gap: 0.25rem;
          cursor: pointer;
          color: #1773b0;
          svg {
            font-size: 1.25rem;
          }
        }
        .payment-gateway-details {
          .payment-header {
            padding: 1rem;
            background-color: #f0f5ff;
            border: 1px solid #1673b0;
            border-radius: 0.5rem 0.5rem 0 0;
            p {
              margin-bottom: 0;
              font-size: 1rem;
              line-height: normal;
              color: var(--jk-clr-02);
            }
          }
          .payment-body {
            background-color: #f4f4f4;
            border: 1px solid #dedede;
            padding: 1rem;
            border-radius: 0 0 0.5rem 0.5rem;
            img {
              max-width: 10rem;
              display: block;
              margin: 0 auto;
            }
            p {
              margin-bottom: 0;
              font-size: 1rem;
              line-height: normal;
              color: var(--jk-clr-02);
              text-align: center;
            }
          }
        }
        .address-save-as-field {
          margin-bottom: 0.5rem;
          label {
            color: var(--jk-clr-02);
            font-size: 1rem;
            line-height: normal;
          }
          .location-name-wrapper {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-top: 0.75rem;
            input[type = "radio"]:checked + .l-name {
              background-color: var(--jk-clr-02);
              color: var(--jk-clr-01);
              border: 1px solid var(--jk-clr-02);
              span {
                color: var(--jk-clr-01) !important;
              }
            }
            .l-name {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              padding: 0.35rem 1rem;
              border: 1px solid var(--jk-clr-08);
              border-radius: 0.5rem;
              svg {
                font-size: 1rem;
              }
              span {
                font-size: 1rem;
                line-height: 1;
                color: var(--jk-clr-02);
              }
              &.active {
                background-color: var(--jk-clr-02);
                color: var(--jk-clr-01);
                border: 1px solid var(--jk-clr-02);
                span {
                  color: var(--jk-clr-01) !important;
                }
              }
            }
          }
        }
        .billing-details-wrapper {
          margin-top: 1rem;
          .billing-checkbox {
            padding: 0.5rem 1rem;
            border: 1px solid #dedede;
            &:nth-child(1) {
              border-radius: 0.5rem 0.5rem 0 0;
              border-bottom: none;
            }
            &:nth-child(2) {
              border-radius: 0 0 0.5rem 0.5rem;
            }
          }
        }
      }
    }
    .itemDetails__section {
      background-color: #f5f5f5;
      padding: 1rem;
      @include media-min-lg {
        min-height: calc(100vh - 66px);
        width: 40%;
        padding: 3rem 1rem;
        position: sticky;
        top: 6rem;
      }
      .total-container {
        position: sticky;
        top: 6rem;
        .total-price-wrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-top: 2rem;
          @include media-min-lg {
            max-width: 25rem;
          }
          .price-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
              font-size: rem;
              line-height: normal;
              color: var(--jk-clr-02);
              margin-bottom: 0;
            }
          }
          span {
            font-size: 0.8rem;
            color: var(--jk-clr-09);
          }
        }
        button {
          @include media-min-lg {
            max-width: 25rem;
          }
        }
      }
    }
  }
}
