.success-card {
  max-width: 25rem;
  background-color: var(--jk-clr-01);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3rem auto 0rem auto;

  @include media-min-lg {
    margin: 6rem auto 4rem auto;
  }
  h1 {
    font-size: 1.25rem;
    line-height: normal;
    color: var(--jk-clr-02);
    margin-bottom: 0;
    text-align: center;
  }
  .success-icon {
    max-width: 5rem;
    height: 5rem;
    margin: 0 auto;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h2 {
      font-size: 1rem;
      line-height: normal;
      color: var(--jk-clr-02);
      margin-bottom: 0;
      text-align: center;
    }
    p {
      margin: 0;
      color: var(--jk-clr-02);
      font-size: 1rem;
      line-height: normal;
      text-align: center;
    }
    button {
      display: block;
      margin: 0 auto;
    }
  }
}
