.bottom-buy-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  padding: 1rem;
  background-color: var(--jk-clr-01);
  border-top: 2px solid var(--jk-clr-03);
  .price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    p {
      margin-bottom: 0;
      font-size: 1rem;
      line-height: normal;
      color: var(--jk-clr-02);
    }
  }
}
