.shopping-info__card {
  background-color: var(--jk-clr-01);
  max-width: 500px;
  min-width: 280px;
  margin: 0 auto;
  font-family: $font-family-3;
  border: none;
  overflow: hidden;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .card-header {
    display: flex;
    align-items: center;
    position: sticky !important;
    background-color: var(--jk-clr-01);
    padding: 1rem;
    top: 0;
  }
  .title {
    font-size: 1rem;
    line-height: normal;
    color: var(--jk-clr-02);
    position: relative;
    &::after {
      margin-top: 0.3rem;
      display: block;
      content: '';
      width: 50px;
      height: 4px;
      background-color: var(--jk-clr-02);
    }
  }
  .close_icon {
    margin-left: auto;
    display: block;
    width: fit-content;
    cursor: pointer;
    display: none;
    @include media-min-md {
      display: block;
    }
    svg {
      font-size: 1.5rem;
    }
  }
  .shipping-term_wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0rem 1rem 1rem 1rem;
    p {
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.6;
      color: var(--jk-clr-02);
      span {
        font-weight: 400;
        color: var(--jk-clr-04);
        font-size: 0.95rem;
      }
    }
  }
}

