.most-popular-product__section {
  .mostPopular___container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    @include media-min-xl {
      gap: 2rem;
    }
    .mostPopular__card {
      cursor: pointer;
      width: 100%;
      overflow: hidden;
      @include media-min-alt(360px) {
        width: calc(50% - 0.5rem);
      }
      @include media-min-md {
        width: calc(33.33% - 0.69rem);
      }
      @include media-min-lg {
        width: calc(25% - 0.75rem);
      }
      @include media-min-xl {
        width: calc(20% - 1.6rem);
      }
      .card-image {
        border-radius: 0.75rem 0.75rem 0 0;
        border: 1px solid var(--jk-clr-03);
        padding: 1rem;
        border-bottom: 1px solid transparent;
        height: 12rem;
        overflow: hidden;
        @include media-min-md {
          height: 15rem;
        }
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      .card-body {
        background-color: var(--jk-clr-03);
        padding: 0.75rem 1rem;
        border-radius: 0 0 0.75rem 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        h6 {
          margin: 0;
          color: var(--jk-clr-05);
          font-style: normal;
          font-weight: 600;
          font-size: 0.75rem;
          line-height: normal; /* 100% */
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include media-min-sm {
            font-size: 1.25rem;
          }
        }
        .solid_and_rating {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .rating {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.1875rem 0.375rem;
            gap: 0.25rem;
            border-radius: 0.25rem;
            border: 1px solid var(--jk-clr-05);
            background: var(--jk-clr-05);
            @include media-min-sm {
              padding: 0.375rem 0.5rem;
            }
            span {
              color: var(--jk-clr-02);
              font-style: normal;
              font-weight: 400;
              font-size: 0.75rem;
              line-height: 0.75rem; /* 100% */
              @include media-min-sm {
                font-size: 1rem;
                line-height: 1rem; /* 100% */
              }
            }
            img {
              width: 0.75rem !important;
              height: 0.75rem !important;
              @include media-min-sm {
                width: 1rem !important;
                height: 1rem !important;
              }
            }
          }
          .line {
            width: 0.0625rem;
            height: 1rem;
            background: #D9D9D9;
          }
          p {
            margin: 0;
            color: #FFF;
            font-style: normal;
            font-weight: 600;
            font-size: 0.65rem;
            line-height: 0.65rem; /* 100% */
            @include media-min-sm {
              font-size: 1rem;
              line-height: 1rem; /* 100% */
            }
          }
        }
        .price_wrapper {
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;
          .price {
            margin: 0;
            color: var(--jk-clr-05);
            font-style: normal;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 0.75rem; /* 100% */
            @include media-min-sm {
              font-size: 1rem;
              line-height: 1rem; /* 100% */
            }
          }
          .discount_price {
            margin: 0;
            color: var(--jk-clr-05);
            font-style: normal;
            font-weight: 400;
            text-decoration-line: line-through;
            font-size: 0.75rem;
            line-height: 0.75rem; /* 100% */
            @include media-min-sm {
              font-size: 1rem;
              line-height: 1rem; /* 100% */
            }
          }
          p {
            margin: 0;
            color: var(--jk-clr-05);
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 0.75rem; /* 100% */
            @include media-min-sm {
              font-size: 1rem;
              line-height: 1rem; /* 100% */
            }
          }
        }
        .free_delivery {
          margin: 0;
          color: var(--jk-clr-01);
          font-style: normal;
          font-size: 0.75rem;
          line-height: 0.75rem; /* 100% */
          @include media-min-sm {
            font-size: 1rem;
            line-height: 1rem; /* 100% */
          }
        }
      }
    }
  }
}
