.product-info__section {
  padding-top: 2rem;
  @include media-min-lg {
    padding-top: 3rem;
  }
  .product-info__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 80rem;
    margin: 0 auto;
    @include media-min-lg {
      gap: 4rem;
      flex-direction: row;
    }
    .product-image {
      border-radius: 0.75rem;
      border: 2px solid #285430;
      overflow: hidden;
      padding: 1rem;
      width: 100%;
      @include media-min-lg {
        max-height: 35rem;
        padding: 2rem;
        width: calc(50% - 2rem);
        position: sticky;
        top: 6rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .product-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      width: 100%;
      @include media-min-lg {
        width: calc(50% - 2rem);
      }

      .common-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        h6 {
          margin: 0;
          color: var(--jk-clr-04);
          font-size: 1rem;
          font-weight: 600;
          line-height: 1; /* 100% */
        }
        h1 {
          margin: 0;
          color: var(--jk-clr-03);
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.25;
          @include media-min-sm {
            font-size: 2rem;
          }
        }
        .price-wrapper {
          display: flex;
          align-items: flex-start;
          gap: 0.75rem;
          p {
            margin: 0;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: normal; /* 100% */
            color: var(--jk-clr-03);
            &.original-price {
              color: var(--jk-clr-02);
              text-decoration: line-through;
            }
          }
        }
        .rating-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.75rem;
          p {
            margin: 0;
            color: #000;
            font-size: 1rem;
            font-weight: 400;
            line-height: normal; /* 100% */
          }
        }
        .by-author {
          margin: 0;
          color: var(--jk-clr-04);
          font-size: 1rem;
          font-weight: 400;
          line-height: normal; /* 100% */
          span {
            color: var(--jk-clr-03);
          }
        }
      }
      .line {
        width: 100%;
        height: 0.0625rem;
        background: #0000004d;
      }
      .desc {
        margin-bottom: 0;
        color: var(--jk-clr-04);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.6;
      }
      .product-current__details {
        padding: 0;
        margin: 0 !important;
        list-style-position: inside;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        li {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
          span {
            font-size: 1rem;
            font-weight: 500;
            line-height: normal; /* 100% */
            &.text-green {
              color: #285430;
            }
            &.text-red {
              color: #FA7070;
            }
            &.text-litegreen {
              color: #4E944F;
            }
          }
        }
      }
      .quatity-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
        .btn-quatity {
          display: flex;
          padding: 0.375rem 0.75rem;
          align-items: flex-start;
          gap: 0.625rem;
          border-radius: 0.25rem;
          border: 2px solid var(--jk-clr-03);
          background: var(--jk-clr-01);
          color: var(--jk-clr-03);

          font-size: 1rem;
          font-weight: 500;
          line-height: 1rem; /* 100% */
          &.active,&:hover {
            background-color: var(--jk-clr-03);
            color: var(--jk-clr-01);
          }
        }
      }
      .add-to-cart-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 0.75rem;
        width: 100%;
        .plus-minus-quatity {
          display: flex;
          padding: 0.5rem 0.75rem;
          justify-content: center;
          align-items: center;
          gap: 0.75rem;
          border-radius: 0.25rem;
          border: 2px solid var(--jk-clr-03);
          span {
            color: var(--jk-clr-02);
            font-size: 1rem;
            font-weight: 500;
            line-height: normal;
          }
        }
        .btn-add-cart {
          display: flex;
          padding: 0.5rem 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          flex: 1 0 0;
          border-radius: 0.25rem;
          background: var(--jk-clr-03);
          border: 2px solid var(--jk-clr-03);
          color: var(--jk-clr-01);
          text-align: center;
          font-size: 1rem;
          font-weight: 500;
          line-height: normal;
          height: 100%;
          &:hover {
            background-color: var(--jk-clr-01);
            color: var(--jk-clr-03);
          }
        }
        .btn-watchlist {
          display: flex;
          padding: 0.5rem 0.75rem;
          align-items: flex-start;
          gap: 0.625rem;
          border-radius: 0.25rem;
          border: 2px solid var(--jk-clr-03);
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
      .btn-buynow {
        display: flex;
        padding: 0.5rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        flex: 1 0 0;
        border-radius: 0.25rem;
        background: var(--jk-clr-01);
        border: 2px solid var(--jk-clr-03);
        color: var(--jk-clr-03);
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        &:hover {
          background-color: var(--jk-clr-03);
          color: var(--jk-clr-01);
        }
      }
      .text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        width: 100%;
        @include media-min-md {
          flex-direction: row;
          align-items: flex-start;
        }
        .item {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
          span {
            color: var(--jk-clr-02);
            font-size: 1rem;
            font-weight: 400;
            line-height: normal; /* 100% */
          }
        }
      }
      .deliveryinfo {
        display: flex;
        align-items: flex-start;
        gap: 0.75rem;
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
        span {
          color: var(--jk-clr-02);
          font-size: 1rem;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .other-section__wrapper {
    border: 1px solid #0000004d;
    padding: 1rem;
    max-width: 80rem;
    margin: 2rem auto;
    border-radius: 0.5rem;
    .custumer-review__section {
      .custumer-review__header {
        .title-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h2 {
            margin-bottom: 0.5rem;
            color: var(--jk-clr-02);
            font-size: 1.25rem;
            line-height: normal;
          }
        }
      }
      .custumer-review__body {
        @include media-min-lg {
          padding: 1rem 1rem 0rem 1rem;
        }
        @include media-min-xl {
          padding: 2rem 1rem 0rem 1rem;
        }
      }
    }
  }
  .product-description__wrappper {
    max-width: 85rem;
    margin: 0 auto;
    margin-top: 2rem;
    .product-description__title {
      h3 {
        margin-bottom: 0;
        color: var(--jk-clr-02);
        font-size: 1.25rem;
        line-height: normal;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-top: 1rem;
      li {
        color: var(--jk-clr-04);
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.6;
        margin-bottom: 0.5rem;
      }
    }
  }
}
