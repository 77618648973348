.cart-items__wrapper {
  padding: 1rem 0;
  .cart-items__container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    .cart-item__details {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include media-min-sm {
        gap: 0.5rem;
      }
      @include media-min-lg {
        width: calc(70% - 1rem);
      }
    }

    .payment__details {
      width: 100%;

      @include media-min-lg {
        width: calc(30% - 1rem);
      }
      .payment-card {
        background-color: var(--jk-clr-06);
        .payment-details__header {
          display: flex;
          justify-content: space-between;
          padding: 0.75rem 1rem;
          border-bottom: 1px solid var(--jk-clr-04);
          h6 {
            margin: 0;
            font-size: 1.25rem;
            line-height: normal;
            color: var(--jk-clr-04);
          }
        }
        .payment-details__body {
          padding: 0rem 1rem;
          .price-details-wrapper {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            padding: 1rem 0rem;
            border-bottom: 1px solid var(--jk-clr-04);
          }
          .price-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              margin: 0;
              font-size: 1rem;
              line-height: normal;
              color: var(--jk-clr-02);
            }
            .price {
              span {
                font-size: 1rem;
                line-height: normal;
                .discount {
                  text-decoration: line-through;
                }
              }
            }
          }
          .total-price__wrapper {
            padding: 1rem 0rem;
          }
          .mge-wrapper {
            padding: 1rem 0rem;
            border-top: 1px solid var(--jk-clr-04);
            p {
              margin: 0;
              font-size: 1rem;
              font-weight: 600;
              line-height: normal;
              color: var(--jk-clr-07);
            }
          }
        }
      }
    }
  }
}
