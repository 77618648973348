.cart-wrapper {
  width: 100%;
  height: 100vh;
  background-color: var(--jk-clr-01);
  @include media-min-sm {
    width: 25rem;
  }
  @include scroll-bar(0,0);
  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--jk-clr-06);
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--jk-clr-01);
    h6 {
      margin: 0;
      font-size: 1.25rem;
      line-height: normal;
      color: var(--jk-clr-03);
      font-weight: 500;
    }
    svg {
      cursor: pointer;
    }
  }
  .cart-body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: calc(100% - 3.875rem);
    overflow-y: auto;
    padding-bottom: 5rem;
    @include scroll-bar(0.3rem,0.3rem);

    .coupen-code__wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      p {
        margin: 0;
        font-size: 1rem;
        line-height: normal;
        color: var(--jk-clr-04);
      }
      .coupen-code-input__wrapper {
        position: relative;
        .cutter-icon {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: auto;
          width: 3rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        input {
          border: 2px dashed var(--jk-clr-04);
          background-color: var(--jk-clr-01);
          width: 100%;
          outline: none !important;
          box-shadow: none !important;
          padding: 0.5rem 2rem 0.5rem 3rem;
          font-size: 0.9rem;
          &:focus {
            border: 2px dashed var(--jk-clr-04);
            outline: none !important;
            box-shadow: none !important;
          }
        }
        .right-arrow {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          left: auto;
          width: 2rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .details-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          margin: 0;
          font-size: 1rem;
          line-height: normal;
          font-weight: 400;
          color: var(--jk-clr-02);
          &.price {
            color: var(--jk-clr-03);
            font-weight: 600;
          }
        }
      }
      .line {
        width: 100%;
        height: 1px;
        border: 1px dashed var(--jk-clr-04);
      }
    }
  }
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: var(--jk-clr-01);
    border-top: 1px solid var(--jk-clr-06);
    .price-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      p {
        margin: 0;
        font-size: 1.25rem;
        line-height: 1;
        font-weight: 600;
        color: var(--jk-clr-03);
      }
      span {
        font-size: 0.8rem;
        color: var(--jk-clr-04);
      }
    }
  }
}
