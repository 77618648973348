.card4 {
  border: none;
  background-color: var(--jk-clr-01);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  background-color: var(--jk-clr-06);
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  &.full-viewCard {
    padding: 1rem 0rem !important;
    border-radius: 0 !important;
    background-color: var(--jk-clr-01) !important;
    border-bottom: 1px solid var(--jk-clr-06);
    align-items: flex-start;
    @include media-min-sm {
      align-items: center;
    }
    &:last-child {
      border-bottom: 1px solid transparent;
    }
    .card-image {
      background-color: var(--jk-clr-06);
    }
    .card-content {
      .quatity-container{
        justify-content: unset !important;
        gap: 1rem;
        .quatity-wrapper {
          .box {
            background-color: var(--jk-clr-06) !important;
          }
        }
      }
    }
  }
  .card-image {
    background-color: var(--jk-clr-01);
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 0.5rem;
    width: 5.5rem;
    height: 5.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .card-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 6.5rem);
    gap: 0.3rem;
    @include media-min-sm {
      gap: 0.5rem;
    }
    h2 {
      margin: 0;
      font-size: 1rem;
      line-height: 1.3;
      color: var(--jk-clr-03);
      @include lineBreak(2);
      @include media-min-sm {
        font-size: 1.25rem;
        line-height: 1.65;
        @include lineBreak(1);
      }
    }
    p {
      margin: 0;
      font-size: 0.75rem;
      line-height: normal;
      color: var(--jk-clr-04);
      @include lineBreak(1);
      @include media-min-sm {
        font-size: 1rem;
      }
    }
    .price {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      p {
        margin: 0;
        font-size: 0.9rem;
        line-height: normal;
        color: var(--jk-clr-03);
        font-weight: 600;
        &.offer-price {
          color: var(--jk-clr-04);
          font-weight: 400;
          text-decoration: line-through;
          font-size: 0.75rem;
          @include media-min-sm {
            font-size: 0.9rem;
          }
        }
      }
    }
    .quatity-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .quatity-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .box {
          cursor: pointer;
          background-color: var(--jk-clr-01);
          padding: 0rem 0.25rem;
        }
        p {
          margin: 0;
          font-size: 0.9rem;
          line-height: 1;
          color: var(--jk-clr-03);
          font-weight: 600;
        }
      }
      p {
        cursor: pointer;
        display: flex !important;
        align-items: center;
        gap: 0.25rem;
        margin: 0;
        font-size: 1rem;
        line-height: normal;
        color: var(--jk-clr-04);
      }
    }
  }
}
