// ============== Standard Bootstrap Mixins ===================
// ============================================================

// min width mixins

@mixin media-min-sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin media-min-md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin media-min-lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin media-min-xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

@mixin media-min-xxl {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

// max width mixins

@mixin media-max-sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

@mixin media-max-md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin media-max-lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

@mixin media-max-xl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}

@mixin media-max-xxl {
  @media (max-width: #{$screen-xxl}) {
    @content;
  }
}

// min to max width mixins

@mixin media-min-sm-max-md {
  @media (min-width: #{$screen-sm}) and (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin media-min-md-max-lg {
  @media (min-width: #{$screen-md}) and (max-width: #{$screen-lg}) {
    @content;
  }
}

@mixin media-min-lg-max-xl {
  @media (min-width: #{$screen-lg}) and (max-width: #{$screen-xl}) {
    @content;
  }
}

@mixin media-min-xl-max-xxl {
  @media (min-width: #{$screen-xl}) and (max-width: #{$screen-xxl}) {
    @content;
  }
}

// ===================== Custom Mixins ========================
// ============================================================

// Custom min width mixins

@mixin media-min-alt363 {
  // ====  Example for min ====
  @media (min-width : #{$screen-alt-363}) {
    @content;
  }
}

@mixin media-min-alt($px) {
  @media (min-width : #{$px}) {
    @content;
  }
}

// Custom max width mixins

@mixin media-max-alt363 {
  // ====  Example for max ====
  @media (max-width : #{$screen-alt-363}) {
    @content;
  }
}

@mixin media-max-alt($px) {
  @media (max-width : #{$px}) {
    @content;
  }
}

// custom min to max mixins

@mixin media-min-max-alt($min, $max) {
  @media (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}

// =======================
// Linear Gradient Mixins
// =======================

// Gradient backgrounds

@mixin bg-gradient($parent, $gradient) {
  #{$parent} {
    background: $gradient;
  }
}

// cursor

@mixin cursor($name,$value) {
  #{$name} {
    cursor: $value;
  }
}

@mixin lineBreak($line,$showoverflow : "ellipsis") {
  overflow: hidden;
  text-overflow: $showoverflow;
  display: -webkit-box !important;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin scroll-bar($width:0.2rem,$height:0.2rem) {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
      background-color: #707070;
      outline: 0.05rem solid #707070;
      border-radius: 50rem;
  }
}
