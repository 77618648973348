// ==================================
// ===  Linear Gradient Variable  ===
// ==================================

$gradients: ();
// key : linear gradient color

// Gradient Classes

@each $gradient, $value in $gradients {
  @include bg-gradient(".bg-gradient-#{$gradient}", $value);
}

$count: 5;
$width_unit: "%";

@while $count < 100 {
  .width-#{$count} {
    width: #{$count}#{$width_unit} !important;
  }

  $count: $count + 5;
}


$cursor : (
  pointer : pointer,
  none : none,
);

@each $name, $value in $cursor {
  @include cursor(".cursor-#{$name}", $value);
}
