.ask-question-popup, .add-review__form {
  background-color: var(--jk-clr-01);
  max-width: 500px;
  min-width: 280px;
  margin: 0 auto;
  font-family: $font-family-3;
  border: none;
  // overflow: hidden;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  width: 100%;
  &.add-review__form {
    @include media-min-md {
      min-width: 25rem;
    }
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .card-header {
    display: flex;
    align-items: center;
    position: sticky !important;
    background-color: var(--jk-clr-01);
    padding: 1rem;
    top: 0;
  }
  .title {
    font-size: 1rem;
    line-height: normal;
    color: var(--jk-clr-03);
    margin-bottom: 0.5rem;
    position: relative;
    &::after {
      margin-top: 0.3rem;
      display: block;
      content: '';
      width: 50px;
      height: 4px;
      background-color: var(--jk-clr-03);
    }
  }
  .close_icon {
    margin-left: auto;
    display: block;
    width: fit-content;
    cursor: pointer;
    display: none;
    @include media-min-md {
      display: block;
    }
    svg {
      font-size: 1.5rem;
    }
  }
  .ask-question-popup-content_wrapper ,.add-review__form-content_wrapper {
    padding: 0rem 1rem 1rem 1rem;
    .card {
      margin: 2rem 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      border: none;
      .card-image {
        width: 5rem;
        height: 5rem;
        padding: 0.5rem;
        border-radius: 0.25rem;
        background-color: #f4f4f4;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          mix-blend-mode: multiply;
        }
      }
      .content {
        width: calc(100% - 6rem);
        h6 {
          margin-bottom: 0.5rem;
          color: var(--jk-clr-03);
          font-size: 1.15rem;
          line-height: 1.5;
          @include lineBreak(2);
        }
        p {
          margin-bottom: 0;
          font-size: 0.95rem;
          color: var(--jk-clr-02);
        }
      }
    }
  }
}
